.container {
  height: 100%;
  background-position: 120% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: hidden;
}

@media screen and (min-width: 800px) {
  .container {
    display: grid;
    grid-template-columns: 250px 1fr;
    align-items: center;
    background-position: 40% 0%;
    background-size: contain;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

/*
=========
NAVBAR
=========
*/

.links-container {
  height: 0;
  overflow: hidden;
  transition: 0.2s;
}

.links-container button {
  display: block;
}

.navbar {
  height: auto;
  background-color: #4d6d9a;
  position: absolute;
  width: 100%;
}

.nav-toggle {
  font-size: 1.5rem;
  background: transparent;
  border-color: transparent;
  cursor: pointer;
  outline: none;
  transition: 0.5s;
  color: white;
}

.nav-link {
  background: transparent;
  border-color: transparent;
  cursor: pointer;
  outline: none;
  width: 100%;
  height: 100%;
}

.nav-link {
  color: #ffffff;
  transition: all 0.2s ease-in-out;
}

.nav-link:hover {
  background-color: #344967;
}

@media screen and (min-width: 800px) {
  .links-container {
    height: auto !important;
  }
  .nav-toggle {
    display: none;
  }

  .navbar {
    background: transparent;
    position: static;
    overflow: hidden;
  }

  .single-link-container {
    display: flex;
    background-color: #4d6d9a;
    height: 50px;
    margin: 10px 0 10px 0;
    align-items: center;
  }

  .nav-link {
    font-size: 15px;
    color: #ffffff;
    padding: 0 0 0 40px;
    text-align: left;
  }

  .nav-link:hover {
    padding: 0 0 0 50px;
    font-size: 20px;
    background-color: #344967;
  }
}

/*
=========
PROJECTS
=========
*/

.project-logo {
  width: 300px;
  object-fit: cover;
}

.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(2, 2, 2, 0.6);
  padding: 30px 0 0 0;
  overflow: auto;
  max-height: 100%;
}

.project-title {
  color: white;
  font-size: 20px;
  margin: 0 0 8px 0;
}

.project-language {
  color: white;
  font-size: 16px;
  margin: 0px;
}

.project-desc {
  color: white;
  font-size: 14px;
  margin: 0 0 5px 0;
}

.single-project-container {
  max-width: 500px;
  height: auto;
  margin: 10px;
  text-align: center;
}

@media screen and (min-width: 800px) {
  .projects-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px;
    margin: 0 0 0 20px;
  }

  .single-project-container {
    width: 200px;
    height: auto;
    margin: 10px;
  }

  .project-logo {
    width: 200px;
  }
}

/*
======
ABOUT
======
*/

.about-text {
  background-color: rgba(2, 2, 2, 0.6);
  padding: 10px 50px 10px 20px;
  color: #ffffff;
}

.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.about-container button {
  font-size: 16px;
  background: transparent;
  border-color: transparent;
  cursor: pointer;
  outline: none;
  color: rgb(114, 233, 98);
}

@media screen and (min-width: 800px) {
  .about-container {
    display: grid;
    grid-template-columns: 40% 60%;
    height: 100%;
    align-items: center;
  }
}

/*
======
CONTACT
======
*/

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.contact-text {
  background-color: rgba(2, 2, 2, 0.6);
  padding: 10px 50px 10px 20px;
  color: #ffffff;
}

.contact-logo {
  width: 50px;
}

.contact-link-container {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  margin: 0 -30px 0 0;
}

.contact-link {
  margin: 10px;
  transition: all 0.2s ease-in-out;
}

.contact-link:hover {
  transform: scale(1.1);
}

.project-language-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-language-container {
  background: #13d353;
  margin: 5px;
}
.single-language-container p {
  padding: 0;
  margin: 3px;
  font-size: 14px;
}

@media screen and (min-width: 800px) {
  .contact-container {
    display: grid;
    grid-template-columns: 40% 60%;
    height: 100%;
    align-items: center;
  }
}
